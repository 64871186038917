<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="설비 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="grid.data"
            :filtering="false"
            :selection="popupParam.selectData.length > 0 ? 'multiple' : 'none'"
            :columnSetting="false"
            :usePaging="false"
            :isExcelDown="false"
            :checkClickFlag="false"
            :editable="editable"
            :noDataLabel="$language('정비계획할 설비를 추가하세요. 저장할 시 정비결과를 등록 할 수 있습니다.')"
            rowKey="minEquipmentMaintenanceId"
            checkDisableColumn="disable"
            @rowClick="rowClick"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <q-btn
                  v-if="editable && !disabled && popupParam.selectData.length > 0"
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      {{$language('버튼설명')}}
                    </div>
                    <div class="tooltipCustom">
                      <!-- ④ 선택 완료 : 체크박스를 선택하여 정비완료 하는 버튼입니다. -->
                      {{$language('④ 선택 완료 : 체크박스를 선택하여 정비완료 하는 버튼입니다.')}}
                      <br>
                      <!-- ⑤ 일괄저장 : 설비목록과 설비별 정비결과를 일괄적으로 저장하는 버튼입니다. -->
                      {{$language('⑤ 일괄저장 : 설비목록과 설비별 정비결과를 일괄적으로 저장하는 버튼입니다.')}}
                      <br>
                    </div>
                  </q-tooltip>
                </q-btn>
                <!-- 선택완료 -->
                <c-btn v-if="editable && !disabled && popupParam.selectData.length > 0" :showLoading="false" label="선택완료" icon="save" color="blue"  @btnClicked="resultComplete" />
                <c-btn v-if="editable && !disabled && popupParam.selectData.length === 0" :showLoading="false" label="완료" icon="check" color="blue"  @btnClicked="resultComplete2" />
                <c-btn
                  v-if="editable && !disabled"
                  :url="updatesUrl"
                  :isSubmit="isSave"
                  :param="grid.data"
                  mappingType="PUT"
                  :label="popupParam.selectData.length > 0 ? '' : '저장'"
                  icon="save"
                  @beforeAction="saveInspection"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'complete' && popupParam.selectData.length > 0">
                <q-btn
                  class="glossy"
                  round 
                  size="sm"
                  :editable="editable"
                  :disable="props.row.disable"
                  color="blue-6"
                  icon="save"
                  @click.stop="resultComplete3(props.row)" />
              </template>
            </template>
          </c-table>
        </div>
      </div>
      <div class="col-8">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <q-form ref="editForm">
            <!-- x(y) 정비결과 -->
            <c-card class="cardClassDetailForm" :title="`${result.equipmentName}(${result.equipmentNo}) ${$language('x(y) 정비결과')}`">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn
                    v-if="saveDisable && !disabled"
                    :url="updateUrl"
                    :isSubmit="isSave2"
                    :param="result"
                    mappingType="PUT"
                    label="저장"
                    icon="save"
                    @beforeAction="saveItemInspection"
                    @btnCallback="saveCallback" 
                  />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-select
                    required
                    :disabled="result.disable"
                    label="정비유형"
                    codeGroupCd="MDM_MAINTENANCE_KIND_CD"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    v-model="result.equipmentCheckTypeCd"
                  ></c-select>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-field
                    required
                    :disabled="result.disable"
                    label="실제정비자"
                    name="maintenanceUserId"
                    type="user" 
                    v-model="result.maintenanceUserId">
                  </c-field>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-datepicker
                    required
                    :disabled="result.disable"
                    :editable="editable"
                    label="실제정비일"
                    name="maintenanceDate"
                    v-model="result.maintenanceDate">
                  </c-datepicker>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-select
                    required
                    :disabled="result.disable"
                    :editable="editable"
                    codeGroupCd="MIM_CHECK_RESULT_CD"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="inspResultAfterMemCd"
                    label="정비후 정비결과"
                    v-model="result.inspResultAfterMemCd"
                  ></c-select>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-datepicker
                    :disabled="result.disable"
                    :editable="editable"
                    label="정비시간"
                    :range="true"
                    :minuteStep="10"
                    type="time"
                    name="maintenanceTimeList"
                    v-model="result.maintenanceTimeList">
                  </c-datepicker>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-field
                    :disabled="result.disable"
                    label="정비부서입회자"
                    name="memDeptEntrantUserId"
                    type="user" 
                    v-model="result.memDeptEntrantUserId">
                  ></c-field>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-text
                    :afterIcon="
                      editable && !disabled
                        ? [
                            {
                              name: 'search',
                              click: true,
                              callbackName: 'searchWorkPermit',
                            },
                            {
                              name: 'close',
                              click: true,
                              callbackName: 'removeWorkPermit',
                            },
                          ]
                        : null
                    "
                    :editable="editable"
                    :close="false"
                    :detail="true"
                    :readonly="true"
                    label="작업허가번호"
                    name="workSummary"
                    v-model="result.workSummary"
                    @searchWorkPermit="searchWorkPermit"
                    @detailPop="detailWorkPermit"
                    @removeWorkPermit="removeWorkPermit"
                  >
                  </c-text>
                </div>
                <div v-if="editable" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-moc 
                    :editable="editable"
                    :disabled="result.disable"
                    :document.sync="result"
                    label="MOC번호"
                    name="sopMocId"
                    v-model="result.sopMocId">
                  </c-moc>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-text
                    :editable="editable"
                    :disabled="result.disable"
                    label="정비자 자격사항"
                    name="maintenanceQualifications"
                    v-model="result.maintenanceQualifications">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    label="정비원인"
                    :rows="2"
                    name="maintenanceCause"
                    v-model="result.maintenanceCause">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    label="정비내용"
                    :rows="2"
                    name="maintenanceContent"
                    v-model="result.maintenanceContent">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    label="정비결과요약"
                    :rows="2"
                    name="maintenanceResultSummary"
                    v-model="result.maintenanceResultSummary">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    :rows="2"
                    label="관리자의 검토의견"
                    name="reviewOpinionOfMananger"
                    v-model="result.reviewOpinionOfMananger">
                  </c-textarea>
                </div>
              </template>
            </c-card>
            <!-- 정비이력 -->
            <c-card class="cardClassDetailForm" title="정비이력">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn label="출력" icon="print" @btnClicked="btnRecord" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-text
                    :editable="editable"
                    :disabled="result.disable"
                    label="보고서번호"
                    name="reportNo"
                    v-model="result.reportNo">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-text
                    :editable="editable"
                    :disabled="result.disable"
                    label="고장심각도"
                    name="failureSeverity"
                    v-model="result.failureSeverity">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-text
                    :editable="editable"
                    :disabled="result.disable"
                    label="고장모드"
                    name="failureMode"
                    v-model="result.failureMode">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-text
                    :editable="editable"
                    :disabled="result.disable"
                    label="누계횟수"
                    name="totalNumber"
                    v-model="result.totalNumber">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-datepicker
                    :disabled="result.disable"
                    :editable="editable"
                    label="운전정지시간"
                    :range="true"
                    :minuteStep="10"
                    type="time"
                    name="stopDrivingTimeList"
                    v-model="result.stopDrivingTimeList">
                  </c-datepicker>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-datepicker
                    :disabled="result.disable"
                    :editable="editable"
                    label="이용불능시간"
                    :range="true"
                    :minuteStep="10"
                    type="time"
                    name="noUseTimeList"
                    v-model="result.noUseTimeList">
                  </c-datepicker>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-datepicker
                    :disabled="result.disable"
                    :editable="editable"
                    :minuteStep="10"
                    :range="true"
                    label="총가동시간"
                    type="time"
                    name="allDrivingTimeList"
                    v-model="result.allDrivingTimeList">
                  </c-datepicker>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    label="관리자확인"
                    :rows="2"
                    name="managerConfirm"
                    v-model="result.managerConfirm">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-maintenance-result-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        selectData: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentMaintenanceId: '',
        plantCd: '',
        equipmentCd: '',
        maintenanceStatusCd: null,
        equipmentTypeCd: '',
        equipmentCheckTypeCd: null,
        memDeptEntrantUserId: '',
        maintenanceDueDate: '',
        maintenanceDueUserId: '',
        sopMocId: '',
        maintenanceUserId: '',
        maintenanceQualifications: '',
        maintenanceResultSummary: '',
        inspResultAfterMemCd: null,
        noPlanResultEnrollFlag: 'N',
        maintenanceCause: '',
        maintenanceContent: '',
        maintenanceStartTime: '',
        maintenanceEndTime: '',
        stopDrivingStartTime: '',
        stopDrivingEndTime: '',
        reviewOpinionOfMananger: '',
        workSummary: '',
        permitTypeCd: '',
        sopWorkPermitId: '',
        reportNo: '',
        managerConfirm: '',
        failureSeverity: '',
        failureMode: '',
        noUseTime: '',
        totalNumber: '',
        allDrivingTime: '',
        stopDrivingTimeList: [],
        maintenanceTimeList: [],
        allDrivingTimeList: [],
        noUseTimeList: [],
      },
      grid: {
        columns: [],
        data: [],
        height: '690px'
      },
      title: '',
      chkRsltYnItems: [],
      insertUrl: '',
      updateUrl: '',
      updatesUrl: '',
      getUrl: '',
      isSave: false,
      isSave2: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      
    };
  },
  computed: {
    disabled() {
      let count = 0;
      this.$_.forEach(this.grid.data, _item => {
        if(_item.disable) count++;
      })
      return count === this.grid.data.length;
    },
    saveDisable() {
      return this.popupParam.selectData.length > 0 && this.result && !this.result.disable && this.editable;
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.min.maintenance.plan.get.url;
      this.updatesUrl = transactionConfig.sop.min.maintenance.result.updates.url;
      this.updateUrl = transactionConfig.sop.min.maintenance.result.update.url;
      this.completeUrl = transactionConfig.sop.min.maintenance.result.completes.url;
      // code setting
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ]
      this.setHeader();
      // list setting
      this.title = this.popupParam.title;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.selectData.length > 0) {
        this.grid.data = [];
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.$http.url = this.$format(this.getUrl, _item.minEquipmentMaintenanceId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data.push(_result.data)
            this.$_.forEach(this.grid.data, _item => {
              if (_item.stopDrivingStartTime && _item.stopDrivingEndTime) {
                _item.stopDrivingTimeList = [_item.stopDrivingStartTime, _item.stopDrivingEndTime];
              }
              if (_item.maintenanceStartTime && _item.maintenanceEndTime) {
                _item.maintenanceTimeList = [_item.maintenanceStartTime, _item.maintenanceEndTime];
              }
            })
            this.rowClick(this.grid.data[0], 'temp')
          },);
        })
      } else {
        if (this.popupParam.minEquipmentMaintenanceId) {
          this.grid.data = [];
          this.$http.url = this.$format(this.getUrl, this.popupParam.minEquipmentMaintenanceId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data.push(_result.data);
            this.$_.forEach(this.grid.data, _item => {
              if (_item.stopDrivingStartTime && _item.stopDrivingEndTime) {
                _item.stopDrivingTimeList = [_item.stopDrivingStartTime, _item.stopDrivingEndTime];
              }
              if (_item.maintenanceStartTime && _item.maintenanceEndTime) {
                _item.maintenanceTimeList = [_item.maintenanceStartTime, _item.maintenanceEndTime];
              }
              if (_item.noUseStartTime && _item.noUseEndTime) {
                _item.noUseTimeList = [_item.noUseStartTime, _item.noUseEndTime];
              }
              if (_item.allDrivingStartTime && _item.allDrivingEndTime) {
                _item.allDrivingTimeList = [_item.allDrivingStartTime, _item.allDrivingEndTime];
              }
            })
            this.rowClick(this.grid.data[0], 'temp')
          },);
        }
      }
    },
    setHeader() {
      if (this.popupParam.selectData.length > 0) {
        this.grid.columns = [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            style: 'width:120px',
            align: 'center',
            sortable: false
          },
          {
            name: 'maintenanceDueUserName',
            field: 'maintenanceDueUserName',
            label: '정비예정자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'maintenanceDueDate',
            field: 'maintenanceDueDate',
            label: '정비예정일',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'complete',
            field: 'complete',
            label: '완료',
            align: 'center',
            style: 'width:50px',
            sortable: false,
            type: "custom",
          },
        ]
      } else {
        this.grid.columns = [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            style: 'width:120px',
            align: 'center',
            sortable: false
          },
          {
            name: 'maintenanceDueUserName',
            field: 'maintenanceDueUserName',
            label: '정비예정자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'maintenanceDueDate',
            field: 'maintenanceDueDate',
            label: '정비예정일',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
        ]
      }
    },
    saveInspection() {
      let flag = false;
      this.$_.forEach(this.grid.data, _item => {
        if (!_item.memDeptEntrantUserId || !_item.equipmentCheckTypeCd || !_item.maintenanceUserId || !_item.inspResultAfterMemCd || !_item.maintenanceDate) {
            window.getApp.$emit('ALERT', {
            title: '안내',
            message: this.$language('필수', {s1: _item.equipmentName}), // '[ ' + _item.equipmentName + ' ]의 필수 입력값을 입력해 주세요. [정비부서 입회자, 정비유형, 실제정비자, 실제정비일, 정비후 정비결과]'
            type: 'warning', // success / info / warning / error
          });
          flag = true;
        }
      })    
      if (!flag) { 
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              if (_item.maintenanceTimeList && _item.maintenanceTimeList.length > 0) {
                _item.maintenanceStartTime = _item.maintenanceTimeList[0];
                _item.maintenanceEndTime = _item.maintenanceTimeList[1];
              } 
              if (_item.stopDrivingTimeList&& _item.stopDrivingTimeList.length > 0) {
                _item.stopDrivingStartTime = _item.stopDrivingTimeList[0];
                _item.stopDrivingEndTime = _item.stopDrivingTimeList[1];
              } 
              if (_item.noUseTimeList&& _item.noUseTimeList.length > 0) {
                _item.noUseStartTime = _item.noUseTimeList[0];
                _item.noUseEndTime = _item.noUseTimeList[1];
              } 
              if (_item.allDrivingTimeList&& _item.allDrivingTimeList.length > 0) {
                _item.allDrivingStartTime = _item.allDrivingTimeList[0];
                _item.allDrivingEndTime = _item.allDrivingTimeList[1];
              } 
            })
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    saveItemInspection() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave2 = !this.isSave2;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
      });
    },
    detailWorkPermit() {
      if (!this.result.sopWorkPermitId) return;
      this.popupOptions.title = '작업허가서 상세'; 
      this.popupOptions.param = {
        sopWorkPermitId: this.result.sopWorkPermitId,
        permitTypeCd: this.result.y,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    resultComplete() {
      let selectData = this.$refs['table'].selected;
      if (selectData && selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.', 
          type: 'warning',
        });
        return;
      }
      let flag = false;
      this.$_.forEach(selectData, _item => {
        if (!_item.memDeptEntrantUserId || !_item.equipmentCheckTypeCd || !_item.maintenanceUserId || !_item.inspResultAfterMemCd || !_item.maintenanceDate) {
            window.getApp.$emit('ALERT', {
            title: '안내',
            message: this.$language('필수 입력값을 입력해 주세요.') + '['+ _item.equipmentName + ']',
            type: 'warning', // success / info / warning / error
          });
          flag = true;
        }
      }) 
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '정비완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)', 
          // TODO : 정비완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, _item => {
              _item.maintenanceCompleteFlag = 'Y';
            })
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData;
            this.$http.request(() => {
              this.$_.forEach(selectData, _item => {
                _item.maintenanceStatusCd = 'MCSC000020';
                _item.disable = true;
              })
              this.$refs['table'].selected = [];
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    resultComplete2() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '정비완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)', // 정비완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)
        // TODO : 정비완료 하시겠습니까?
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid.data, _item => {
            _item.maintenanceCompleteFlag = 'Y';
          })
          this.$http.url = this.completeUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.grid.data;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      })
      }
    },
    // 단일 정비완료
    resultComplete3(data) {
      this.rowClick(data, 'temp')
      if (!data.memDeptEntrantUserId || !data.equipmentCheckTypeCd || !data.maintenanceUserId || !data.inspResultAfterMemCd || !data.maintenanceDate) {
          window.getApp.$emit('ALERT', {
          title: '안내',
          message: '필수 입력값을 입력해 주세요. [정비부서 입회자, 정비유형, 실제정비자, 실제정비일,  정비후 정비결과]', 
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '정비완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)',
        // TODO : 정비완료 하시겠습니까?
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          data.maintenanceCompleteFlag = 'Y';
          this.$http.url = transactionConfig.sop.min.maintenance.result.complete.url;
          this.$http.type = 'PUT';
          this.$http.param = data;
          this.$http.request(() => {
            data.disable = true;
            data.maintenanceStatusCd = 'MCSC000020';
            this.$refs['table'].selected = [];
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
      },
      });
    },
    rowClick(row, col) {
      if (col.name === 'sopMocId') {
        this.linkClick(row);
      } else {
        this.result = row;
      }
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
    // 작업허가서 선택
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 검색"; 
      this.popupOptions.param = {
        type: "single",
        popupMode: 'Y',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.result.sopWorkPermitId = data[0].sopWorkPermitId
        this.result.workSummary = data[0].workSummary // 허가번호
        this.result.permitTypeCd = data[0].permitTypeCd // 허가번호
      }
    },
    removeWorkPermit() {
      this.result.sopWorkPermitId = '';
      this.result.workSummary = '';
      this.result.permitTypeCd = '';
    },
    btnRecord() {
      let thisVue = this;
      this.$http.url = selectConfig.sop.min.equipment.maintain.print.url
      this.$http.type = 'GET';
      this.$http.param = {
        minEquipmentMaintenanceId: this.result.minEquipmentMaintenanceId,
        detailFlag: 'Y',
        equipmentCd: this.result.equipmentCd,
        plantCd: this.result.plantCd,
      };
      this.$http.request(
        _result => {
          let fileOrgNm = this.result.equipmentName + ' ' + this.$language('기기정비이력기록서') +'.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
